define("adt-wss/router", ["exports", "adt-wss/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  }); // eslint-disable-next-line array-callback-return

  Router.map(function () {
    this.route('help', function () {
      this.route('index', {
        path: '/'
      });
      this.route('alpha', {
        path: 'alpha/:help_id'
      });
      this.route('topics', {
        path: 'topics/:topic_id'
      });
      this.route('faq');
      this.route('search', {
        path: 'search/:search_query'
      });
      this.route('testyoursystem', {
        path: 'test-your-system'
      });
      this.route('travelreminders', {
        path: 'travel-reminders'
      });
      this.route('batteries');
      this.route('systemmanuals', {
        path: 'system-manuals'
      });
    });
    this.route('billinghelp');
    this.route('ps', function () {
      this.route('login');
      this.route('expresspay', {
        path: 'ep'
      });
      this.route('auth');
      this.route('login-pic');
      this.route('easypay', {
        path: 'ez'
      });
      this.route('login-acc');
      this.route('reset-pic');
      this.route('logout');
      this.route('creditenquiryform', {
        path: 'creditenquiryform/:chatID'
      });
    });
    this.route('dashboard', function () {
      this.route('index', {
        path: '/'
      });
      this.route('account', function () {
        this.route('payments', function () {
          this.route('savedpayments', {
            path: 'saved-payments'
          });
          this.route('onetimepayment', {
            path: 'one-time-payment'
          });
          this.route('manageeasypay', {
            path: 'manage-easypay'
          });
        });
        this.route('profile');
        this.route('preferences');
        this.route('contacts');
        this.route('documents');
        this.route('loading');
      });
      this.route('alarm', {
        path: 'alarm-system'
      }, function () {
        this.route('system', function () {
          this.route('systemidentification', {
            path: 'system-identification/:csno'
          });
        });
        this.route('systemtest', {
          path: 'system-test'
        });
        this.route('appointments');
        this.route('history');
        this.route('loading');
      });
      this.route('manage', {
        path: 'locations'
      }, function () {
        // manage locations
        this.route('users');
        this.route('businessreporting');
      });
      this.route('shop', {
        path: 'orders'
      }, function () {
        this.route('checkout', function () {
          this.route('batteryid', {
            path: 'battery/:batteryid'
          });
          this.route('thankyou', {
            path: 'thank-you'
          });
          this.route('adhesivekit', {
            path: 'adhesive-kit/:quantityvalue'
          });
          this.route('kitthankyou', {
            path: 'kit-thank-you'
          });
        });
        this.route('productsservices', {
          path: 'products-services'
        }, function () {
          this.route('products');
          this.route('battery');
          this.route('decals');
          this.route('contactrepairkit', {
            path: 'contact-repair-kit'
          });
        });
        this.route('orderhistory', {
          path: 'order-history'
        });
      });
      this.route('loading');
      this.route('manage-loading');
      this.route('sitemap');
      this.route('suggestedfeatures', {
        path: 'suggested-features'
      });
    });
    this.route('index', {
      path: '/'
    }, function () {
      this.route('resetpassword', {
        path: 'reset-password'
      });
      this.route('resetpassword.uuid', {
        path: 'reset-password/:uuid'
      });
      this.route('resetpasswordforsms', {
        path: 'reset-password-ivrsms'
      });
      this.route('resetpasswordforchat', {
        path: 'reset-password-chat'
      });
      this.route('expresspay', {
        path: 'express-pay'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('remote', {
          path: 'r'
        }, function () {
          this.route('uuid', {
            path: ':uuid'
          });
        });
      });
      this.route('3gconversion');
      this.route('home', {
        path: '/'
      }, function () {
        this.route('ich', {
          path: 'ich/:phone'
        });
      });
      this.route('registeruuid', {
        path: 'register/:uuid'
      });
      this.route('loading');
      this.route('usageagreement', {
        path: 'usage-agreement'
      });
      this.route('systemidentification', {
        path: 'system-identification/:csno'
      });
    });
    this.route('loading');
    this.route('dashboard-loading');
    this.route('crime', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('404', {
      path: '/*path'
    });
    this.route('home');
  });
  var _default = Router;
  _exports.default = _default;
});