define("adt-wss/pods/components/sierra-chat-include/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SierraChatComponent = (_class = class SierraChatComponent extends Ember.Component {
    toggleChatWindow() {
      console.log("Chat window open/closed");
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "toggleChatWindow", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChatWindow"), _class.prototype)), _class);
  _exports.default = SierraChatComponent;
});