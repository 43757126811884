define("adt-wss/pods/index/resetpasswordforchat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gf8DtyoO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"resetPasswordForChat\"],[8],[0,\"\\n\\t\"],[1,[28,\"myadt-login/forgot-password\",null,[[\"apiSource\"],[\"chat\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/index/resetpasswordforchat/template.hbs"
    }
  });

  _exports.default = _default;
});