define("adt-wss/pods/components/dashboard/account/contacts/emergency-contact/component", ["exports", "ember-copy", "adt-wss-common/mixins/with-fsm", "adt-wss/mixins/on-form-esc-handler", "adt-wss-common/classes/validator", "adt-wss-common/utils/ember-helpers", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp", "adt-wss/pods/components/dashboard/account/contacts/emergency-contact/constants"], function (_exports, _emberCopy, _withFsm, _onFormEscHandler, _validator, _emberHelpers, _myadtHelpers, _fp, constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IDLE_STATE,
    EDIT_STATE,
    UPDATING_STATE,
    SET_EDIT_MODE_ACTION,
    CANCEL_EDIT_MODE_ACTION,
    CANCEL_PRE_DELETE_ACTION,
    UPDATE_ACTION,
    SUCCESSFUL_UPDATE_ACTION,
    FAILURE_UPDATE_ACTION,
    SUCCESSFUL_DELETE_ACTION,
    FAILURE_DELETE_ACTION,
    DELETING_STATE,
    PRE_DELETE_STATE,
    SET_PRE_DELETE_MODE_ACTION,
    DELETE_ACTION
  } = constants;
  const fsmModel = {
    transitions: {
      [IDLE_STATE]: {
        [SET_EDIT_MODE_ACTION]: EDIT_STATE,
        [SET_PRE_DELETE_MODE_ACTION]: PRE_DELETE_STATE
      },
      [PRE_DELETE_STATE]: {
        [CANCEL_PRE_DELETE_ACTION]: IDLE_STATE,
        [DELETE_ACTION]: DELETING_STATE
      },
      [EDIT_STATE]: {
        [UPDATE_ACTION]: UPDATING_STATE,
        [CANCEL_EDIT_MODE_ACTION]: IDLE_STATE
      },
      [UPDATING_STATE]: {
        [FAILURE_UPDATE_ACTION]: EDIT_STATE,
        [SUCCESSFUL_UPDATE_ACTION]: IDLE_STATE
      },
      [DELETING_STATE]: {
        [FAILURE_DELETE_ACTION]: PRE_DELETE_STATE,
        [SUCCESSFUL_DELETE_ACTION]: IDLE_STATE
      }
    }
  };

  const createValidatorInstance = () => new _validator.default({
    firstName: {
      label: 'First name',
      rules: ['isNotEmpty', 'maxLength?20']
    },
    lastName: {
      label: 'Last name',
      rules: ['isNotEmpty', 'maxLength?20']
    },
    password: {
      label: 'Verbal password',
      rules: ['minLength?3', 'maxLength?10']
    },
    extensions: {
      isArray: true,
      label: index => "Extension #".concat(index),
      rules: ['maxLength?10']
    },
    phoneNumbers: {
      label: index => "Phone number #".concat(index),
      rules: ['isPhoneNumber'],
      isArray: true
    },
    phoneTypes: {
      label: index => "Phone type #".concat(index),
      rules: ['isNotEmpty'],
      isArray: true
    },
    signalChatOptTypes: {
      label: index => "SingalChat Type #".concat(index),
      isArray: true
    },
    chatVideoSharingFlagTypes: {
      label: index => "VideoSharing type #".concat(index),
      isArray: true
    }
  }, {
    messageCustomizer: (value, label) => "".concat(label, ": ").concat(value)
  });

  const defaultProps = {
    contact: {},
    phoneTypes: [{
      text: 'Select',
      value: null
    }, {
      text: 'Home',
      value: 'H'
    }, {
      text: 'Work',
      value: 'W'
    }, {
      text: 'Mobile',
      value: 'M'
    }, {
      text: 'Pager',
      value: 'P'
    }, {
      text: 'Landline',
      value: 'L'
    }],
    phoneTypesToAdd: [{
      text: 'Select',
      value: null
    }, {
      text: 'Landline',
      value: 'L'
    }, {
      text: 'Mobile',
      value: 'M'
    }],
    signalChatOptTypes: [{
      text: 'Select',
      value: null
    }, {
      text: 'Yes',
      value: true
    }, {
      text: 'No',
      value: false
    }],
    chatVideoSharingFlagTypes: [{
      text: 'Select',
      value: null
    }, {
      text: 'Yes',
      value: true
    }, {
      text: 'No',
      value: false
    }],
    availableToDelete: false,
    avaibleToDragAndDrop: false,
    disabledActions: false,
    type: 'predispatch',
    setEditStatus: () => {},
    hasDuplications: false,
    systemPhoneNumbersDuplication: false,

    determinePhoneDuplication() {
      console.error('Please pass "determinePhoneDuplication" method to dashboard/account/contacts/emergency-contact component');
    },

    onSubmit() {
      console.error('Please pass "onSubmit" method to dashboard/account/contacts/emergency-contact component');
    },

    cancelAdding() {
      console.error('Please pass "cancelAdding" method to dashboard/account/contacts/emergency-contact component');
    }

  };
  const MAX_PHONE_NUMBERS = 5;
  /**
   * @module Components/Dashboard/Account/Contacts/EmergencyContact
   *
   * @prop {Boolean} new
   * @prop {String} type
   * @prop {Boolean} availableToDelete
   * @prop {Boolean} avaibleToDragAndDrop
   * @prop {Boolean} disabledActions
   * @prop {Object} contact
   * @prop {Object[]} contact.phoneNumbers
   * @prop {Boolean} hasDuplications
   * @prop {Function} determinePhoneDuplication
   * @prop {Function} setEditStatus
   * @prop {Function} onSubmit
   * @prop {Boolean} systemPhoneNumbersDuplication
   * @example
   * {@lang xml} {{ dashboard/account/contacts/emergency-contact
   *  orderNum=(inc index)
   *  contact=item
   *  type='predispatch'
   *  onSubmit=(action (if item.new 'createContact' 'editContact'))
   *  setEditStatus=(action 'setEditStatus')
   *  cancelAdding=(action 'cancelAddingContact')
   *  disabledActions=false
   *  availableToDelete=false
   *  avaibleToDragAndDrop=false
   *  determinePhoneDuplication=(action 'determinePhoneDuplication')
   *  hasDuplications=false
   *  systemPhoneNumbersDuplication=true
   *  delete=(action 'deleteContact')
   * }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, _onFormEscHandler.default, {
    constants,
    fsmModel,
    MAX_PHONE_NUMBERS,
    mwd: Ember.inject.service('modal-window-dispatcher'),
    expanded: false,
    currentState: (0, _emberHelpers.computedApplyFunction)(newStatus => newStatus ? EDIT_STATE : IDLE_STATE, 'new'),
    activeESCState: EDIT_STATE,
    cancelESCState: CANCEL_EDIT_MODE_ACTION,
    IS_IDLE_STATE: (0, _emberHelpers.isState)(IDLE_STATE),
    IS_EDIT_STATE: (0, _emberHelpers.isState)(EDIT_STATE),
    IS_PRE_DELETE_STATE: (0, _emberHelpers.isState)(PRE_DELETE_STATE),
    updating: (0, _emberHelpers.computedSome)('currentState', UPDATING_STATE, DELETING_STATE),
    classNames: ['account-emergency-contact'],
    classNameBindings: ['IS_EDIT_STATE:is-editing', 'IS_PRE_DELETE_STATE:is-editing', 'hasDuplications', 'systemPhoneNumbersDuplication'],
    name: (0, _emberHelpers.computedApplyFunction)(({
      firstName: fN,
      lastName: lN
    }) => "".concat(fN, " ").concat(lN), 'contact'),
    disabledToAddPhone: (0, _emberHelpers.computedApplyFunction)((m, l) => l >= m, 'MAX_PHONE_NUMBERS', 'data.phoneNumbers.length'),
    isNew: Ember.computed.oneWay('contact.new'),
    showPassword: false,
    contactData: Ember.inject.service(),
    sitePhoneNumbers: Ember.computed.oneWay('contactData.sitePhoneNumbers'),
    allContactNumbers: Ember.computed.oneWay('contactData.allContactNumbers'),
    processedContactNumbers: (0, _emberHelpers.computedApplyFunction)((allContactNumbers, originalValues = []) => originalValues.reduce((acc, {
      number,
      extension
    }) => {
      const n = (0, _myadtHelpers.removePhoneMask)(number).concat(extension);
      const i = acc.indexOf(n);

      if (i < 0) {
        return acc;
      }

      return acc.filter((e, idx) => idx !== i);
    }, allContactNumbers), 'allContactNumbers', 'originalValues'),
    isDupe: false,
    hadDupe: false,
    dupesCleared: false,
    originalValues: null,

    init() {
      this._super(...arguments);

      this.keyDown = this.onEscHandler.bind(this);
      this.prepareEdit();
      this.set('validator', createValidatorInstance());
      this.get('validator').onValidate(({
        messages: errors,
        values
      }) => {
        this.set('errors', errors);
        this.set('messages', values);
      });
    },

    [UPDATE_ACTION]() {
      this.get('data.phoneNumbers').forEach(n => delete n.dupeState);
      this.get('data.phoneNumbers').forEach(n => delete n.alaramMsgState);
      this.get('data.phoneNumbers').forEach((n, index) => {
        this.resetAlarmMessageFlag(n, index);
      });
      const {
        fsm,
        validator,
        data,
        dupesCleared,
        hadDupe
      } = this.getProperties(['validator', 'data', 'fsm', 'dupesCleared', 'hadDupe']);
      const payload = (0, _fp.omix)(data, {
        phoneNumbers: data.phoneNumbers.map(i => i.number),
        phoneTypes: data.phoneNumbers.map(i => i.type),
        extensions: data.phoneNumbers.map(i => i.extension),
        signalChatOptTypes: data.phoneNumbers.map(i => i.signalChatOptIn),
        chatVideoSharingFlagTypes: data.phoneNumbers.map(i => i.chatVideoSharingFlag)
      });
      const validationFailed = validator.validate(Ember.isPresent(payload.password) ? payload : (0, _fp.omit)(payload, 'password'));

      if (validationFailed || !dupesCleared && hadDupe) {
        return Promise.reject(fsm.dispatch(FAILURE_UPDATE_ACTION));
      }

      this.resetMessages();
      return this.commonDispatcher(() => this.submit((0, _fp.omit)(data, 'new')), SUCCESSFUL_UPDATE_ACTION, FAILURE_UPDATE_ACTION);
    },

    [DELETE_ACTION]() {
      const {
        contactNo,
        ctaclinkNo
      } = this.get('data');
      return this.commonDispatcher(() => this.delete({
        contactNo,
        ctaclinkNo
      }), SUCCESSFUL_DELETE_ACTION, FAILURE_DELETE_ACTION);
    },

    [SET_EDIT_MODE_ACTION]() {
      this.prepareEdit();
      this.setEditStatus(true);
      this.actions.duplicateNumTest.call(this, this.get('data.phoneNumbers'));
      this.get('data.phoneNumbers').forEach((n, index) => Ember.set(n, 'alaramMsgState', this.showAlarmMessage(n)));
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackPageview', '/dashboard/account/contacts.jsp/EditContactChosen']);
    },

    [SET_PRE_DELETE_MODE_ACTION]() {
      this.setEditStatus(true);
    },

    [CANCEL_EDIT_MODE_ACTION]() {
      this.setProperties({
        isDupe: false,
        hadDupe: false,
        dupesCleared: false
      });
      this.setEditStatus(false);
      this.resetMessages();

      if (this.get('isNew')) {
        this.cancelAdding();
      }
    },

    [CANCEL_PRE_DELETE_ACTION]() {
      this.setEditStatus(false);
    },

    [SUCCESSFUL_UPDATE_ACTION]() {
      this.setEditStatus(false);
    },

    [SUCCESSFUL_DELETE_ACTION]() {
      this[SUCCESSFUL_UPDATE_ACTION]();
    },

    submit() {
      return this.onSubmit(...arguments);
    },

    prepareEdit() {
      const contact = this.get('contact');
      this.set('data', (0, _emberCopy.copy)(contact, true));
      const numberList = this.get('data.phoneNumbers');
      this.set('originalValues', numberList);
      const numsForEditing = numberList.map(n => (0, _fp.omix)(n, {
        dupeState: 'non-duplicate',
        alaramMsgState: 'alaram-msg-txt-no'
      }));
      this.set('data.phoneNumbers', numsForEditing);
    },

    resetMessages() {
      this.set('messages', null);
      this.set('errors', null);
    },

    resetAlarmMessageFlag(phoneNum, index) {
      let phoneTypeValue = phoneNum.type.toString();
      let alaramMessageFlag = phoneNum.signalChatOptIn.toString();

      if (phoneTypeValue != 'M' && alaramMessageFlag === 'true') {
        this.set("data.phoneNumbers.".concat(index, ".signalChatOptIn"), JSON.parse('false'));
      }
    },

    /**
    * Sets the dupeState property for the number. Called in duplicateNumTest action.
    * returns 'non-duplicate' if input number hasn't changed or is not a duplicate
    * returns 'duplicate-phone-number' if duplicate detected in same contact or system.
    */
    applyDupeState(currentNumber, numberList, index) {
      const originalNumber = this.get('originalValues')[index] || {};
      const {
        number,
        extension
      } = currentNumber;
      const processedContactNumbers = this.get('processedContactNumbers').concat(numberList.map(c => (0, _myadtHelpers.removePhoneMask)(c.number).concat(c.extension)));
      const currentInputValue = "".concat((0, _myadtHelpers.removePhoneMask)(number)).concat(extension);
      const dupeInContact = numberList.filter(n => "".concat((0, _myadtHelpers.removePhoneMask)(n.number)).concat(n.extension) === currentInputValue);
      const dupesInSystem = processedContactNumbers.filter(n => n === currentInputValue);
      const fullFormat = "".concat((0, _myadtHelpers.removePhoneMask)(originalNumber.number)).concat(originalNumber.extension);
      const phoneExists = dupesInSystem.length <= 1 && dupeInContact.length === 1;

      if (originalNumber && currentInputValue === fullFormat && phoneExists) {
        return 'non-duplicate'; // current input val identical to original number
      }

      if (dupeInContact.length > 1 || dupesInSystem.length > 1) {
        this.setProperties({
          isDupe: true,
          hadDupe: true,
          dupesCleared: false
        });
        return 'duplicate-phone-number';
      }

      return 'non-duplicate';
    },

    /**
    * Test input values and show alarm message if landline is selected and alarmmessage is set to yes
    */
    showAlarmMessage(currentPhoneDetails) {
      let phoneTypeValue = currentPhoneDetails.type.toString();
      let alaramMessageFlag = currentPhoneDetails.signalChatOptIn.toString();

      if (phoneTypeValue != 'M' && alaramMessageFlag === 'true') {
        return 'alaram-msg-txt';
      }

      return 'alaram-msg-txt-no';
    },

    actions: {
      detectPhoneType(type) {
        const {
          text
        } = this.get('phoneTypes').find(el => el.value === type) || {
          text: ''
        };
        return text;
      },

      detectSignalChatOptType(type) {
        const {
          text
        } = type != null && this.get('signalChatOptTypes').find(el => el.value != null && el.value.toString() === type.toString()) || {
          text: 'Not specified'
        };
        return text;
      },

      detectChatVideoSharingFlagType(type) {
        const {
          text
        } = type !== null && this.get('chatVideoSharingFlagTypes').find(el => el.value != null && el.value.toString() === type.toString()) || {
          text: 'Not specified'
        };
        return text;
      },

      isBooleanEqual(value1, value2) {
        if (value1 == null && value2 == 'Select') {
          return true;
        }

        return value1 !== null && value1 !== undefined && value2 !== null && value2 != undefined && value1.toString() === value2.toString(); // return value1 == value2;
      },

      changePhoneTypeHandler(index, type) {
        this.set("data.phoneNumbers.".concat(index, ".type"), type);
        let alarmMsgFlag = this.get("data.phoneNumbers.".concat(index, ".signalChatOptIn")).toString();

        if (type != 'M' && alarmMsgFlag === 'true') {
          this.set("data.phoneNumbers.".concat(index, ".alaramMsgState"), 'alaram-msg-txt');
        } else {
          this.set("data.phoneNumbers.".concat(index, ".alaramMsgState"), 'alaram-msg-txt-no');
        }
      },

      changeSignalChatOptTypeHandler(index, type) {
        if (type === "false") {
          this.set("data.phoneNumbers.".concat(index, ".chatVideoSharingFlag"), null);
        }

        this.set("data.phoneNumbers.".concat(index, ".signalChatOptIn"), JSON.parse(type));
        let phoneType = this.get("data.phoneNumbers.".concat(index, ".type")).toString();

        if (type === 'true' && phoneType != 'M') {
          this.set("data.phoneNumbers.".concat(index, ".alaramMsgState"), 'alaram-msg-txt');
        } else {
          this.set("data.phoneNumbers.".concat(index, ".alaramMsgState"), 'alaram-msg-txt-no');
        }
      },

      changeChatVideoSharingFlagHandler(index, type) {
        this.set("data.phoneNumbers.".concat(index, ".chatVideoSharingFlag"), JSON.parse(type));
      },

      addNewPhoneNumber() {
        if (this.get('disabledToAddPhone')) {
          return false;
        }

        return this.get('data.phoneNumbers').pushObject({
          extension: '',
          number: '',
          seqNo: '',
          type: null
        });
      },

      deletePhoneNumber(item) {
        this.get('data.phoneNumbers').removeObject(item);
        this.actions.duplicateNumTest.call(this, this.get('data.phoneNumbers'));
      },

      collapse() {
        this.toggleProperty('expanded');
      },

      toggleShowPassword() {
        this.toggleProperty('showPassword');
      },

      /**
      	* Tests input values for pre/post dispatch contacts phone number text fields and performs appropriate
      	* actions if a duplicate number in system is detected.
      	*/
      duplicateNumTest(numberList) {
        // Adds the current dupeState for each editable number
        numberList.forEach((n, index) => Ember.set(n, 'dupeState', this.applyDupeState(n, numberList, index)));
        const dupesRemaining = numberList.filter(n => n.dupeState === 'duplicate-phone-number'); // All previous dupes have been cleared

        if (this.get('hadDupe') && dupesRemaining.length === 0) {
          this.set('isDupe', false);
          this.set('dupesCleared', true);
        }
      }

    }
  });

  _exports.default = _default;
});