define("adt-wss/pods/components/sierra-chat-include/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVj1qkm+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"sierra-chat-btn\"],[3,\"action\",[[23,0,[]],\"toggleChatWindow\"]],[8],[0,\"Chat with an Agent\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adt-wss/pods/components/sierra-chat-include/template.hbs"
    }
  });

  _exports.default = _default;
});