define("adt-wss/pods/components/dashboard/support-widget/component", ["exports", "adt-wss-common/mixins/with-fsm", "adt-wss/config/environment", "adt-wss-common/utils/fp"], function (_exports, _withFsm, _environment, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.systemTroubleshootingLinks = _exports.links = void 0;
  const links = {
    systemTroubleshooting: [{
      route: 'https://help.adt.com/s/article/Peripheral-Device-Troubleshooting',
      title: 'Replacing a Sensor'
    }, {
      route: 'https://help.adt.com/s/article/General-Battery-Help',
      title: 'Battery'
    }, {
      route: 'https://help.adt.com/s/article/System-Panel-Troubleshooting',
      title: 'System Panel'
    }, {
      route: '',
      title: 'Troubleshooting'
    }, {
      route: 'https://help.adt.com/s/',
      title: 'Product Support'
    }, {
      route: 'https://help.adt.com/s/article/Troubleshooting',
      title: 'Alarm Issues'
    }, {
      route: 'https://help.adt.com/s/article/Command-System-R5-Update-Overview',
      title: 'Panel Updates'
    }],
    order: [{
      route: 'dashboard.shop.productsservices.battery',
      external: false,
      title: 'Batteries'
    }, {
      route: 'dashboard.shop.productsservices.decals',
      external: false,
      title: 'Yard Signs'
    }, {
      route: 'dashboard.shop.productsservices',
      external: false,
      title: 'Products & Services'
    }],
    cyberSecurity: [{
      route: 'help.alpha',
      id: '242',
      title: 'Included Protection Services'
    }, {
      route: 'help.alpha',
      id: '242',
      title: 'What personal information is monitored?'
    }, {
      route: 'help.alpha',
      id: '242',
      title: 'What should I do if I get an Alert?'
    }, {
      route: 'help.alpha',
      id: '241',
      title: 'How do I Reset my Identity Protection portal password?'
    }, {
      route: 'help.alpha',
      id: '241',
      title: 'Who\'s Covered Under My Subscription?'
    }, {
      route: 'help.alpha',
      id: '241',
      title: 'How do I access the monitoring services?'
    }, {
      route: 'help.alpha',
      id: '240',
      title: 'View all FAQs'
    }],
    adtGo: [{
      route: 'help.alpha',
      id: '299',
      title: 'Resolve Connection Issues'
    }, {
      route: 'help.alpha',
      id: '292',
      title: 'SOS Disabled Button'
    }, {
      route: 'help.alpha',
      id: '291',
      title: 'Inaccurate Locations'
    }, {
      route: 'help.alpha',
      id: '294',
      title: 'Add Family Member'
    }, {
      route: 'help.alpha',
      id: '294',
      title: 'Join Family Circle'
    }, {
      route: 'help.alpha',
      id: '126',
      title: 'View all FAQs'
    }]
  };
  _exports.links = links;
  const systemTroubleshootingLinks = {
    systemTroubleshootingURLs: [{
      route: 'https://help.adt.com/s/article/Peripheral-Device-Troubleshooting',
      title: 'Replacing a Sensor'
    }, {
      route: 'https://help.adt.com/s/article/General-Battery-Help',
      title: 'Battery'
    }, {
      route: 'https://help.adt.com/s/article/System-Panel-Troubleshooting',
      title: 'System Panel'
    }, {
      route: '',
      title: 'Troubleshooting'
    }, {
      route: 'https://help.adt.com/s/',
      title: 'Product Support'
    }, {
      route: 'https://help.adt.com/s/article/Troubleshooting',
      title: 'Alarm Issues'
    }, {
      route: 'https://help.adt.com/s/article/Command-System-R5-Update-Overview',
      title: 'Panel Updates'
    }]
  };
  _exports.systemTroubleshootingLinks = systemTroubleshootingLinks;
  const defaultProps = {
    links,
    systemTroubleshootingLinks,
    image: '',
    style: '',
    genericImage: "".concat(_environment.default.rootURL, "images/v2/temp/dashboard-support-generic.jpg"),
    available: false,
    inService: false,
    elementId: 'supportWidget',
    csNo: null
  };
  /**
   * @module Components/Dashboard/SupportWidget
   * @prop {String} elementId - ID attribute for div component wrapper
   * @prop {String} style - HTML Atribute
   * @prop {String} image - Image of widget
   * @prop {Object[]} links - List of links
   * @prop {Object[]} systemTroubleshootingLinks - list of troubleshoot links
   * @prop {Boolean} available - Indicator of availability of a primary system
   * @prop {String} genericImage - Generic image
   * @example
   * {@lang xml} {{ dashboard/support-widget
  		elementId='supportWidget'
  		links={ systemTroubleshooting: [{ route: 'help.alpha', id: '07', external: true, title: 'First' }], order: [{ route: 'shop', external: false, title: 'Shop' }] }
  		image='http://via.placeholder.com/260x195'
  		genericImage='images/v2/temp/dashboard-support-generic.jpg'
  		available=false
  		style='"width: 600px"'
  }}
   * External links need an action method in the future
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    ET: Ember.inject.service('event-tagging'),
    tagName: 'section',
    classNames: ['support-widget', 'widget-section'],
    currentLocation: Ember.inject.service(),
    systemInformation: Ember.inject.service(),
    hideOrderLinks: Ember.computed.bool('currentLocation.roles.3'),
    cyberOnly: Ember.computed.oneWay('currentLocation.cyberOnly'),
    adtGoOnly: Ember.computed.oneWay('currentLocation.adtGoOnly'),
    noSystems: Ember.computed.oneWay('currentLocation.noSystems'),
    currentSystem: Ember.computed.oneWay('systemInformation.selectedSystem'),

    didReceiveAttrs() {
      const systemInfoData = this.get('systemInfoData') || {};
      const linkList = this.get('links');
      this.setProperties({
        csNo: systemInfoData.csNo,
        image: systemInfoData.systemImageUrl,
        available: systemInfoData.nativeImage,
        inService: systemInfoData.inService || false
      }); // system.csNoAlias - system SYSTEM_STANDARDIZED_NAME

      if (systemInfoData.troubleShootUrl) {
        let linksList = this.get('systemTroubleshootingLinks');
        let updatedLinks = linksList.systemTroubleshootingURLs.map(link => {
          if (link.title === 'Troubleshooting') {
            return (0, _fp.omix)(link, {
              external: true,
              href: systemInfoData.troubleShootUrl
            });
          }

          return link;
        }); // Remove "Panel Updates" link for systems other than "Command 5” Touchscreen" or "Command 7" Touchscreen"

        const currentSystemAlias = systemInfoData.csNoAlias;

        if (currentSystemAlias !== "Command 5” Touchscreen" && currentSystemAlias !== "Command 7\" Touchscreen" && currentSystemAlias !== "Command 5\" Touchscreen" && currentSystemAlias !== "Command 7” Touchscreen") {
          updatedLinks = updatedLinks.filter(link => link.title !== 'Panel Updates');
        }

        this.set('links.systemTroubleshooting', updatedLinks);
      }

      if (this.get('currentLocation.cyberOnly')) {
        this.set('genericImage', "".concat(_environment.default.rootURL, "images/v2/temp/cyber-dashboard-generic.jpg"));
      } else if (this.get('currentLocation.adtGoOnly')) {
        this.set('genericImage', "".concat(_environment.default.rootURL, "images/v2/temp/adtgo-dashboard-generic.jpg"));
      }
    },

    actions: {
      eventTag(title) {
        if (title === 'Troubleshooting') {
          const eventTagInfo = {
            eventTypeId: 'TRLINKD',
            subEventTypeId: 'VIEW'
          };
          return this.get('ET').sendEventTag(eventTagInfo);
        }
      },

      handlerOnClick(title, link) {
        this.actions.eventTag.call(this, title, link).then(() => {
          window.open(link, '_blank' // <- This is what makes it open in a new window.
          );
        });
      }

    }
  });

  _exports.default = _default;
});